import { customAttribute, inject } from 'aurelia-framework';

@inject(Element)
@customAttribute('preserve-line-breaks')
export class PreserveLineBreaks {

    /**
     * Constructor
     *
     * @param element
     */
    constructor(element) {
        this.element = element;
    }

    /**
     * Handles value changed
     */
    valueChanged() {
        let text = this.value;

        text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

        this.element.innerHTML = text;
    }
}
